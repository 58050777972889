@import "../../../baseTheme/settings";

.clp-banner {
    position: relative;
    margin:0 auto 62px;

    @include media-breakpoint-up(md) {
        margin:0 auto 72px;
    }

    .banner-image {
        position: relative;

        img {
            display: block;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
    .banner-caption {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        align-content: flex-end;
        max-width: 1410px;
        position: absolute;
        overflow: hidden;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        @include media-breakpoint-up(md) {
            align-content: center;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .caption-wrapper {
            display: flex;
            color: #1E1E1E;
            flex-direction: column;
            flex-grow: 1;
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
                max-width: 470px;
            }

            h2 {
                font-size: 60px;
                line-height: 60px;
                letter-spacing: -0.06em;

                @include media-breakpoint-down(md) {
                    font-size: 36px;
                    line-height: 38px;
                    margin-bottom: 8px;
                }
            }

            p {
                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }

            h1 {
                margin: 0 0 1rem;
                font-weight: 400;
                letter-spacing: -0.113rem;
                font-size: 2.25rem;
                line-height: 2.25rem;
                @include media-breakpoint-up(md) {
                    font-size: 4.5rem;
                    line-height: 4.5rem;
                    letter-spacing: -0.27rem; 
                }

            }

            .banner-cta {
                .btn {
                    height: auto;
                    padding: 0 25px;
                    text-transform: uppercase;
                    line-height: 40px;
                    display: inline-block;
                    vertical-align: top;
                    .icon-cart {
                        width: 17px;
                        height: 17px;
                        margin-bottom: 2px;
                        margin-right: 4px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    
                }
            }

        }

    }

    &.banner-small-banner {
        max-width: 1440px;
        .banner-image {
            &:before {
                height: 400px;
                @include media-breakpoint-up(xl) {
                    height: 315px;
                }
            }
        }

        .banner-caption {
            padding-top: 30px;
            align-content: flex-start;
            @include media-breakpoint-up(md) {
                align-content: center;
                padding-top: 0;
            }
        }

        .caption-wrapper {
            @include media-breakpoint-up(md) {
                text-align: left;
                max-width: 400px;
            }
            h2 {
                margin: 0 0 16px;
                font-weight: 400;
                letter-spacing: -0.113rem;
                font-size: 24px;
                line-height: 28px;
                @include media-breakpoint-up(md) {
                    font-size: 36px;
                    line-height: 38px;
                    letter-spacing: -0.27rem; 
                }
            }
        }
    }

}